import { render, staticRenderFns } from "./PublicProfile.vue?vue&type=template&id=74b8204c&scoped=true&"
import script from "./PublicProfile.vue?vue&type=script&lang=js&"
export * from "./PublicProfile.vue?vue&type=script&lang=js&"
import style0 from "./PublicProfile.vue?vue&type=style&index=0&id=74b8204c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../fod/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74b8204c",
  null
  
)

export default component.exports